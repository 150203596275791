body .MuiButton-root {
  border-radius: 0;
}

body .MuiInputBase-root {
  border-radius: 0;
}

body .MuiDialogActions-root {
  padding: 0 24px 20px;
}