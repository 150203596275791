body {
  background: #EFEFEF !important;
  margin: 0;
}

:root {
  --primary-color: #15483c;
  --secondary-color: #89CDA8;
  --secondary-light: rgba(137, 205, 168, 0.3);
  --text-color: #000;
  --light-grey: #cecece;
  --white: #fff;
  --card-dark: #000;
  --card-light: #323232;
  --error: #cd8988;
}


/* width */
::-webkit-scrollbar {
  /* width: 3px; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--light-grey);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(164, 164, 164);
}

body .MuiOutlinedInput-root {
  border-radius: 6px !important;
}

.estimation_modal--open {
  /* transform: translateX(0) !important; */
  display: block !important;
}

body .MuiModal-root {
  z-index: 130000;
}